import { NineActions, NineButton } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import translate from "src/lib/translate";

import PatientInsuranceBloc from "src/state/PatientInsuranceBloc/PatientInsuranceBloc";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Data from "src/ui/components/Data/Data";
import DocumentPreview from "src/ui/components/DocumentPreview/DocumentPreview";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import { PlaceholderWrapper } from "../PharmacyInformationDetail/PharmacyInformationDetail";

const CardImages = styled.div`
  display: flex;
  margin-top: -0.67rem;
  margin-bottom: 1rem;
  gap: 0.4rem;
`;

export const PROFILE_INSURANCE_INFO_ROUTE = "/app/profile/insurance";
const InsuranceInformationDetail: FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [{ pharmacyInsurances = [] }, { loadInsurances }] =
    useBloc(PatientInsuranceBloc);

  useEffect(() => {
    void loadInsurances().finally(() => setLoading(false));
  }, []);

  const openSelect = () => {
    AppQueryPopupsController.openPopup(AppPopup.profileEnterPharmacyInsurance, {
      onEvent: {
        popupClosed: () => {
          setLoading(true);
          void loadInsurances().finally(() => setLoading(false));
        }
      }
    });
  };
  return (
    <div>
      <OverlayBackButton show alwaysUseFallback fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={translate("insuranceInformation")}></AppPageTitle>
      <nine-spacer s={"md"}></nine-spacer>

      {!loading && pharmacyInsurances.length === 0 && (
        <>
          <PlaceholderWrapper>
            <p className="color-c-60 as-subhead strong m0">
              <Translate msg="insurance.notAdded" />
            </p>
          </PlaceholderWrapper>

          <nine-spacer s={"sm"}></nine-spacer>
          <NineActions style={{ justifyContent: "center" }}>
            <NineButton onClick={() => openSelect()}>
              <Translate msg="insurance.add" />
            </NineButton>
          </NineActions>
        </>
      )}

      <AsyncContent height="30rem" check={[!loading]}>
        {pharmacyInsurances.map((insurance, index) => (
          <div key={index}>
            <Data.Table
              title={
                <>
                  {insurance.isPrimary ? (
                    <Translate msg="insurance.primary" />
                  ) : (
                    <Translate msg="insurance.secondary" />
                  )}
                  {insurance.isPrimary ? (
                    <></>
                  ) : (
                    <span className={"color-c-60"}> {index}</span>
                  )}
                </>
              }
            >
              <Data.Row title="Rx BIN / IIN" data={insurance.rxBin} />
              <Data.Row title="Rx Group ID" data={insurance.rxGrp} />
              <Data.Row
                title={translate("cardholderId")}
                data={insurance.cardHolderId}
              />
              <Data.Row title="Rx PCN" data={insurance.rxPcn} />
            </Data.Table>
            {insurance.frontPhotoFileId && insurance.backPhotoFileId && (
              <CardImages>
                <DocumentPreview id={insurance.frontPhotoFileId} />
                <DocumentPreview id={insurance.backPhotoFileId} />
              </CardImages>
            )}
            <nine-spacer s={"sm"}></nine-spacer>
          </div>
        ))}
      </AsyncContent>
    </div>
  );
};

export default InsuranceInformationDetail;
